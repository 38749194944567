.contact-form {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease-in-out;
  }
  
  .contact-form.visible {
    max-height: 500px; /* Adjust based on form height */
  }

  /* admin login */
  .login {
    margin: 20px auto;
    padding: 40px 50px;
    max-width: 300px;
    border-radius: 5px;
    background: #fff;
    box-shadow: 1px 1px 1px #666;
  }
    .login input {
      width: 100%;
      display: block;
      box-sizing: border-box;
      margin: 10px 0;
      padding: 14px 12px;
      font-size: 16px;
      border-radius: 2px; 
      font-family: Raleway, sans-serif;
    }
  
  .login input[type=text],
  .login input[type=password] {
    border: 1px solid #c0c0c0;
    transition: .2s;
  }
  
  .login input[type=text]:hover {
    border-color: #ff1313;
    outline: none;
    transition: all .2s ease-in-out;
  } 
  
  .login input[type=submit] {
    border: none;
    background: #ff1313;
    color: white;
    font-weight: bold;  
    transition: 0.2s;
    margin: 20px 0px;
  }
  
  .login input[type=submit]:hover {
    background: #ff1313;  
  }
  
    .login h2 {
      margin: 20px 0 0; 
      color: #ff1313;
      font-size: 28px;
    }
  
  .login p {
    margin-bottom: 40px;
  }
  
  .t-decoration-none{
    text-decoration: none;
  }